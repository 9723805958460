import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import TTDashboard from "../views/dashboard/TTDashboard.vue";
import FBDashboard from "../views/dashboard/FBDashboard.vue";
import KwaiDashboard from "../views/dashboard/KwaiDashboard.vue";
import GoogleDashboard from "../views/dashboard/GoogleDashboard.vue";
import CombinedDashboard from "../views/dashboard/CombinedDashboard.vue";
import DataGroups from "../views/DataGroups.vue";
import UserManagement from "../views/UserManagement.vue";
import DataBoard from "@/views/DataBoard.vue";
import NodeTest from "@/views/NodeTest.vue";
import store from "@/store";
import HotWords from "@/views/HotWords.vue";
import DataDetail from "../views/dashboard/DataDetail.vue";
import DailySummary from "../views/tt-management/DailySummary.vue";
import DataSummary from "../views/tt-management/DataSummary.vue";
import DetailedData from "../views/tt-management/DetailedData.vue";
import DeliveryConfig from "../views/tt-management/DeliveryConfig.vue";
import TTManagement from "../views/tt-management/TTManagement.vue";
import FacebookManagement from "../views/dashboard/FacebookManagement.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/dashboard/tt",
        name: "TTDashboard",
        component: TTDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/fb",
        name: "FBDashboard",
        component: FBDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/kwai",
        name: "KwaiDashboard",
        component: KwaiDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/google",
        name: "GoogleDashboard",
        component: GoogleDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/combined",
        name: "CombinedDashboard",
        component: CombinedDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/data-groups",
        name: "DataGroups",
        component: DataGroups,
        meta: {
            requiresAuth: true,
            allowedTypes: [1, 2, "1,2"],
        },
    },
    {
        path: "/node-test",
        name: "NodeTest",
        component: NodeTest,
        meta: { requiresAuth: true },
    },
    {
        path: "/user-management",
        name: "UserManagement",
        component: UserManagement,
        meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
        path: "/data-board",
        name: "DataBoard",
        component: DataBoard,
    },
    {
        path: "/hot-words",
        name: "HotWords",
        component: HotWords,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/detail/:sid",
        name: "DataDetail",
        component: DataDetail,
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: "/tt-management",
        name: "TTManagement",
        component: TTManagement,
        meta: {
            requiresAuth: true,
            allowedTypes: [1, 2],
        },
        redirect: "/tt-management/daily",
        children: [
            {
                path: "daily",
                name: "DailySummary",
                component: DailySummary,
                meta: { requiresAuth: true },
            },
            {
                path: "summary",
                name: "DataSummary",
                component: DataSummary,
                meta: { requiresAuth: true },
            },
            {
                path: "details",
                name: "DetailedData",
                component: DetailedData,
                meta: { requiresAuth: true },
            },
            {
                path: "config",
                name: "DeliveryConfig",
                component: DeliveryConfig,
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: "/facebook",
        name: "FacebookManagement",
        component: FacebookManagement,
        meta: {
            requiresAuth: true,
            allowedTypes: [1],
        },
    },
    {
        path: "/",
        redirect: "/dashboard/tt",
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.path === "/login") {
        next();
        return;
    }

    try {
        const isLoggedIn = await store.dispatch("user/checkLoginStatus");

        if (!isLoggedIn && to.meta.requiresAuth) {
            store.commit("dataGroups/CLEAR_DATA", null, { root: true });
            store.commit("user/CLEAR_USER_DATA");
            next({
                path: "/login",
                query: { redirect: to.fullPath },
            });
            return;
        }

        if (to.meta.requiresAdmin && !store.state.user.isAdmin) {
            next("/dashboard/tt");
            return;
        }

        if (to.path === "/") {
            next("/dashboard/tt");
            return;
        }

        next();
    } catch (error) {
        console.error("路由守卫错误:", error);
        next("/login");
    }
});

export default router;
