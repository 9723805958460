import { render, staticRenderFns } from "./DeliveryConfig.vue?vue&type=template&id=9b3998ca&scoped=true"
import script from "./DeliveryConfig.vue?vue&type=script&lang=js"
export * from "./DeliveryConfig.vue?vue&type=script&lang=js"
import style0 from "./DeliveryConfig.vue?vue&type=style&index=0&id=9b3998ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b3998ca",
  null
  
)

export default component.exports