<template>
  <div class="tt-management">
    <router-view></router-view>
  </div>
</template>

<script>
    export default {
        name: 'TTManagement'
    }
</script>

<style scoped>
    .tt-management {
        width: 100%;
        height: 100%;
    }
</style> 