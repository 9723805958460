<template>
  <div class="user-management">
    <el-card>
      <div slot="header">
        <span>用户管理</span>
        <el-button 
          type="primary" 
          size="small" 
          @click="dialogVisible = true"
          style="float: right">
          新增用户
        </el-button>
      </div>

      <el-table
        v-loading="storeLoading"
        :data="users"
        style="width: 100%">
        <el-table-column prop="accountName" label="用户名"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="userType" label="用户类型">
          <template slot-scope="scope">
            {{ formatUserTypes(scope.row.userType) }}
          </template>
        </el-table-column>
        <el-table-column prop="whetherAdmin" label="是否管理员">
          <template slot-scope="scope">
            {{ scope.row.whetherAdmin === 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleGetPassword(scope.row)">
              获取密码
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.row)">
              修改类型
            </el-button>
            <el-button
              type="text"
              size="small"
              class="delete-btn"
              @click="handleDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增用户对话框 -->
    <el-dialog
      title="新增用户"
      :visible.sync="dialogVisible"
      width="500px"
      @close="resetForm">
      <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px">
        <el-form-item label="姓名" prop="fullName">
          <el-input v-model.trim="userForm.fullName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="userTypes">
          <el-checkbox-group v-model="userForm.userTypes">
            <el-checkbox :label="1">AFS用户</el-checkbox>
            <el-checkbox :label="2">买量站用户</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增修改用户类型的对话框 -->
    <el-dialog
      title="修改用户类型"
      :visible.sync="editDialogVisible"
      width="500px"
      @close="resetEditForm">
      <el-form :model="editForm" :rules="editRules" ref="editForm" label-width="100px">
        <el-form-item label="用户类型" prop="userTypes">
          <el-checkbox-group v-model="editForm.userTypes">
            <el-checkbox :label="1">AFS用户</el-checkbox>
            <el-checkbox :label="2">买量站用户</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'UserManagement',
    data() {
      return {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        dialogVisible: false,
        submitting: false,
        editDialogVisible: false,
        userForm: {
          fullName: '',
          userTypes: []
        },
        rules: {
          fullName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
          ],
          userTypes: [
            { type: 'array', required: true, message: '请至少选择一个用户类型', trigger: 'change' }
          ]
        },
        editForm: {
          accountName: '',
          userTypes: []
        },
        editRules: {
          userTypes: [
            { type: 'array', required: true, message: '请至少选择一个用户类型', trigger: 'change' }
          ]
        },
        userTypeMap: {
          1: 'AFS用户',
          2: '买量站用户'
        }
      }
    },
    computed: {
      users() {
        return this.$store.state.userManagement.users
      },
      storeLoading() {
        return this.$store.state.userManagement.loading
      },
      storeTotal() {
        return this.$store.state.userManagement.total
      }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      async fetchData() {
        try {
          const { total } = await this.$store.dispatch('userManagement/fetchUsers', {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          })
          this.total = total
        } catch (error) {
          this.$message.error(error.toString())
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1  // 切换每页显示数量时重置为第一页
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.fetchData()
      },
      async handleDelete(row) {
        try {
          await this.$confirm('确认删除该用户吗？', '提示', {
            type: 'warning'
          });

          await this.$store.dispatch('userManagement/deleteUser', row.accountName);
          this.$message.success('删除成功');

          // 如果当前页只有一条数据且不是第一页，删除后自动跳转到上一页
          if (this.users.length === 1 && this.pageNum > 1) {
            this.pageNum--;
          }
          await this.fetchData();
        } catch (error) {
          if (error !== 'cancel') {
            this.$message.error(error.toString());
          }
        }
      },
      resetForm() {
        this.$refs.userForm?.resetFields()
        this.userForm.fullName = ''
        this.userForm.userTypes = []
      },
      async handleSubmit() {
        try {
          await this.$refs.userForm.validate()
          this.submitting = true

          await this.$store.dispatch('userManagement/createUser', {
            fullName: this.userForm.fullName,
            userTypes: this.userForm.userTypes.join(',')
          })

          this.$message.success('创建成功')
          this.dialogVisible = false
          this.resetForm()
          // 创建成功后重置到第一页
          this.pageNum = 1
          await this.fetchData()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.submitting = false
        }
      },
      async handleGetPassword(row) {
        try {
          const password = await this.$store.dispatch(
            'userManagement/getPassword',
            row.accountName
          );

          // 使用 Element UI 的 MessageBox 显示密码
          await this.$alert(password, '用户密码', {
            confirmButtonText: '确定',
            callback: () => {
              // 可以在这里添加复制到剪贴板的功能
            }
          });
        } catch (error) {
          this.$message.error(error.toString());
        }
      },
      handleEdit(row) {
        this.editForm.accountName = row.accountName
        this.editForm.userTypes = row.userType ? row.userType.split(',').map(Number) : []
        this.editDialogVisible = true
      },
      resetEditForm() {
        this.$refs.editForm?.resetFields()
        this.editForm.accountName = ''
        this.editForm.userTypes = []
      },
      async handleEditSubmit() {
        try {
          await this.$refs.editForm.validate()
          this.submitting = true

          await this.$store.dispatch('userManagement/updateUser', {
            accountName: this.editForm.accountName,
            userTypes: this.editForm.userTypes.join(',')
          })

          this.$message.success('修改成功')
          this.editDialogVisible = false
          this.resetEditForm()
          await this.fetchData()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.submitting = false
        }
      },
      formatUserTypes(userType) {
        if (!userType) return '-'
        return userType.split(',')
          .map(type => this.userTypeMap[type] || type)
          .join('、')
      }
    },
    created() {
      this.fetchData()
    },
  }
</script>

<style scoped>
  .pagination-container {
    margin-top: 20px;
    text-align: right;
  }

  .delete-btn {
    color: #F56C6C;
  }

  .el-button+.el-button {
    margin-left: 10px;
  }
</style> 