export default {
    namespaced: true,
    state: {
        dataGroups: [],
        loading: false,
        error: null,
        total: 0,
    },
    mutations: {
        SET_DATA_GROUPS(state, dataGroups) {
            state.dataGroups = dataGroups;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_TOTAL(state, total) {
            state.total = total;
        },
        CLEAR_DATA(state) {
            state.dataGroups = [];
            state.currentGroup = null;
        },
    },
    actions: {
        async fetchDataGroups({ commit }, { pageNum, pageSize, sid }) {
            commit("SET_LOADING", true);
            try {
                let url = `/launch/data/group/list?pageNum=${pageNum}&pageSize=${pageSize}`;
                if (sid) {
                    url += `&sid=${encodeURIComponent(sid)}`;
                }
                const response = await this._vm.$http.post(url);
                const dataGroups = response.data.body.data || [];
                const total = response.data.body.total || 0;
                commit("SET_DATA_GROUPS", dataGroups);
                commit("SET_TOTAL", total);
                return { data: dataGroups, total };
            } catch (error) {
                commit("SET_ERROR", error.message);
                throw (
                    error.response?.data?.msg || error.message || "获取数据失败"
                );
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async createDataGroup({ commit, dispatch }, dataGroup) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/data/group/save?sid=${encodeURIComponent(
                        dataGroup.sid
                    )}&platformType=${encodeURIComponent(
                        dataGroup.platformType.join(",")
                    )}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    await dispatch("fetchDataGroups", {
                        pageNum: 1,
                        pageSize: 10,
                    });
                    return true;
                } else {
                    throw new Error(data.header.msg || "创建数据组失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg ||
                    error.message ||
                    "创建数据组失败"
                );
            }
        },

        async updateDataGroup({ commit, dispatch }, dataGroup) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/data/group/update?sid=${encodeURIComponent(
                        dataGroup.sid
                    )}&platformType=${encodeURIComponent(
                        dataGroup.platformType.join(",")
                    )}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    await dispatch("fetchDataGroups", {
                        pageNum: 1,
                        pageSize: 10,
                    });
                    return true;
                } else {
                    throw new Error(data.header.msg || "更新数据组失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg ||
                    error.message ||
                    "更新数据组失败"
                );
            }
        },

        async deleteDataGroup({ commit, dispatch }, sid) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/data/group/remove?sid=${encodeURIComponent(sid)}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    await dispatch("fetchDataGroups", {
                        pageNum: 1,
                        pageSize: 10,
                    });
                    return true;
                } else {
                    throw new Error(data.header.msg || "删除数据组失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg ||
                    error.message ||
                    "删除数据组失败"
                );
            }
        },
    },
};
