<template>
  <el-container>
    <!-- 顶部导航栏 - 只在非登录页显示 -->
    <el-header height="60px" v-if="!isLoginPage">
      <div class="header-container">
        <!-- 左侧 Logo -->
        <div class="logo">
          数据后台
        </div>
        
        <!-- 中间导航菜单 -->
        <el-menu 
          :default-active="$route.path"
          mode="horizontal" 
          background-color="#fff"
          text-color="#303133"
          active-text-color="#409EFF"
          router
        >
          <el-submenu index="/tt-management" v-if="showTTMenu">
            <template slot="title">
              <i class="el-icon-s-marketing"></i>
              TT投放管理
            </template>
            <el-menu-item index="/tt-management/daily">每日汇总</el-menu-item>
            <el-menu-item index="/tt-management/summary">数据汇总</el-menu-item>
            <el-menu-item index="/tt-management/details">详细数据</el-menu-item>
            <!-- <el-menu-item index="/tt-management/config">投放配置</el-menu-item> -->
          </el-submenu>
          <template v-if="showAllMenus">
             <el-menu-item index="/dashboard/tt">
            <i class="el-icon-data-line"></i>
            TT数据看板
          </el-menu-item>
          <el-menu-item index="/dashboard/fb">
            <i class="el-icon-data-line"></i>
            FB数据看板
          </el-menu-item>
          <el-menu-item index="/dashboard/kwai">
            <i class="el-icon-data-line"></i>
            KWai数据看板
          </el-menu-item>
          
          <el-menu-item index="/node-test">
            <i class="el-icon-monitor"></i>
            节点测试
          </el-menu-item>
          <!-- <el-menu-item index="/hot-words">
            <i class="el-icon-search"></i>
            热词搜索
          </el-menu-item> -->
          </template>
          <el-menu-item index="/data-groups">
            <i class="el-icon-folder"></i>
            数据组
          </el-menu-item>

          <el-menu-item index="/user-management" v-if="isAdmin">
            <i class="el-icon-user"></i>
            用户管理
          </el-menu-item>
        </el-menu>
        
        <!-- 右侧用户信息 -->
        <div class="header-right">
          <span v-if="username" class="username">{{ username }}</span>
          <el-button type="text" @click="handleLogout" v-if="isLoggedIn">
            退出登录
          </el-button>
        </div>
      </div>
    </el-header>

    <!-- 主要内容区域 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'App',
    computed: {
      ...mapState('user', ['userType']),
      isLoginPage() {
        return this.$route.path === '/login'
      },
      loading() {
        return this.$store.state.user.loading;
      },
      isLoggedIn() {
        return this.$store.state.user.isLoggedIn
      },
      isAdmin() {
        return this.$store.state.user.isAdmin
      },
      username() {
        return this.$store.state.user.username
      },
      showAllMenus() {
        return this.userType == 1 || this.userType == '1,2'
      },
      showTTMenu() {
        return this.userType == 2 || this.userType == '1,2'
      },
    },
    methods: {
      async handleLogout() {
        try {
          await this.$store.dispatch('user/logout')
          this.$message.success('退出成功')
          this.$router.push('/login')
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    }
  }
</script>

<style>

  /* 重置一些基础样式 */
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  /* 头部样式 */
  .el-header {
    padding: 0;
    box-sizing: border-box;
    background: #fff;
    box-shadow:
      0 2px 8px rgba(0, 0, 0, 0.08),
      0 1px 0 rgba(255, 255, 255, 0.8) inset;
    position: relative;
    z-index: 1000;
  }

  .header-container {
    width: 100%;
    margin: 0 auto;
    height: 60px;
    padding-left: 15px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }

  /* Logo样式 */
  .logo {
    font-size: 30px;
    font-weight: 500;
    margin-right: 35px;
    white-space: nowrap;
    letter-spacing: 1.1px;
    color: #000;
    position: relative;
    text-shadow:
      0 1px 2px rgba(0, 0, 0, 0.15);
    /* 保留简单的阴影效果 */
  }

  /* 移除 ::before 和 ::after 伪元素，不再需要钢印效果 */
  .logo::before,
  .logo::after {
    display: none;
  }

  /* 导航菜单样式优化 */
  .el-menu.el-menu--horizontal {
    border-bottom: none;
    flex: 1;
    display: flex;
  }

  .el-menu--horizontal>.el-menu-item {
    height: 60px;
    line-height: 60px;
    padding: 0 18px;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  .el-menu--horizontal>.el-submenu .el-submenu__title {
    font-weight: 500 !important;
  }

  .el-menu--horizontal>.el-submenu {
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #409EFF;
    color: #409EFF;
    text-shadow: 0 1px 2px rgba(64, 158, 255, 0.2);
  }

  .el-menu--horizontal>.el-submenu.is-active i {
    color: #409EFF;
    text-shadow: 0 1px 2px rgba(64, 158, 255, 0.2);
  }

  .el-menu--horizontal>.el-menu-item:hover {
    background: linear-gradient(to bottom, rgba(64, 158, 255, 0.1) 0%, rgba(64, 158, 255, 0.05) 100%);
  }

  .el-menu--horizontal>.el-menu-item i {
    margin-right: 5px;
    font-size: 18px;
  }

  /* 右侧用户信息样式 */
  .header-right {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }

  .header-right .el-button--text {
    font-weight: 500;
    padding: 8px 15px;
    transition: all 0.3s ease;
  }

  .header-right .el-button--text:hover {
    color: #409EFF;
    text-shadow: 0 0 3px rgba(64, 158, 255, 0.3);
  }

  .username {
    margin-right: 15px;
    color: #606266;
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
  }

  /* 主要内容区域样式 */
  .el-main {
    width: 100%;
    padding: 0 !important;
    margin: 0 auto;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  /* 响应式处理 */
  @media screen and (max-width: 768px) {
    .logo {
      margin-right: 30px;
    }

    .el-menu--horizontal>.el-menu-item {
      padding: 0 15px;
      font-size: 14px;
    }


    .username {
      display: none;
    }
  }

  /* 添加一个样式，让登录页面占满整个视口高度 */
  .el-container {
    min-height: 100vh;
    flex-direction: column !important;
  }

  .el-menu--popup {
    min-width: 160px;
    padding: 5px 0;
  }
</style>
