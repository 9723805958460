export default {
    namespaced: true,
    state: {
        token: localStorage.getItem("token"),
        isLoggedIn: false,
        isAdmin: false,
        username: localStorage.getItem("username"),
        userId: localStorage.getItem("userId"),
        userInfo: null,
        loading: true,
        userType: null,
    },
    mutations: {
        SET_USER_DATA(state, data) {
            state.token = data.token;
            state.isLoggedIn = true;
            state.isAdmin = data.isAdmin;
            state.username = data.username;
            state.userId = data.userId;
            state.userType = data.userType;

            localStorage.setItem("token", data.token);
            localStorage.setItem("isAdmin", data.isAdmin);
            localStorage.setItem("username", data.username);
            localStorage.setItem("userId", data.userId);
        },
        CLEAR_USER_DATA(state) {
            state.token = null;
            state.isLoggedIn = false;
            state.isAdmin = false;
            state.username = null;
            state.userId = null;
            state.userType = null;

            localStorage.removeItem("token");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("username");
            localStorage.removeItem("userId");
        },
        SET_USER_INFO(state, info) {
            state.username = info.accountName;
        },
        SET_LOGIN_STATUS(state, status) {
            state.isLoggedIn = status;
        },
        SET_ADMIN_STATUS(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        SET_LOADING(state, status) {
            state.loading = status;
        },
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                const response = await this._vm.$http.get(
                    "/launch/user/login",
                    {
                        params: {
                            accountName: credentials.username,
                            accountPass: credentials.password,
                        },
                    }
                );

                const { data } = response;
                if (data.header.code === 0 && data.body) {
                    const userData = {
                        username: credentials.username,
                        isAdmin: data.body.data.whetherAdmin === 1,
                        userType: data.body.data.userType,
                    };
                    commit("SET_USER_DATA", userData);
                    return true;
                } else {
                    throw new Error(data.msg || "登录失败");
                }
            } catch (error) {
                throw error.response?.data?.msg || error.message || "登录失败";
            }
        },

        async logout({ commit }) {
            try {
                const response = await this._vm.$http.get(
                    "/launch/user/logout"
                );
                const { data } = response;

                if (data.header.code === 0) {
                    commit("CLEAR_USER_DATA");
                    return true;
                } else {
                    throw new Error(data.header.msg || "退出登录失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg || error.message || "退出登录失败"
                );
            }
        },

        async checkLoginStatus({ commit, state }) {
            if (state.token && state.isLoggedIn) {
                return true;
            }

            try {
                const response = await this._vm.$http.get("/launch/user/info");
                if (response.data.header.code === 0) {
                    const userData = {
                        username: response.data.body.data.accountName,
                        isAdmin: response.data.body.data.whetherAdmin === 1,
                        token: state.token,
                        userId: response.data.body.data.id || state.userId,
                        userType: response.data.body.data.userType,
                    };

                    commit("SET_USER_DATA", userData);
                    return true;
                }
                return false;
            } catch (error) {
                console.error("检查登录状态失败:", error);
                return false;
            }
        },
    },
};
