<template>
  <div class="facebook-management">
    <div class="header">
      <h2>Facebook 管理</h2>
      <div class="header-controls">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="handleDateChange"
        />
        <el-input
          v-model="searchKeyword"
          placeholder="搜索系列名称"
          clearable
          @clear="handleSearch"
          @keyup.enter.native="handleSearch"
        >
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </div>
    </div>

    <!-- 数据汇总区域 -->
    <div class="summary-section">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="summary-item">
            <span class="summary-label">总花费</span>
            <span class="summary-value">{{ summaryData.totalSpend || '0.00' }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="summary-item">
            <span class="summary-label">总展示次数</span>
            <span class="summary-value">{{ summaryData.totalImpressions || '0' }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="summary-item">
            <span class="summary-label">总点击次数</span>
            <span class="summary-value">{{ summaryData.totalClicks || '0' }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="summary-item update-time">
            <span class="summary-label">更新时间</span>
            <span class="summary-value time">{{ summaryData.updateTime || '-' }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 数据表格 -->
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%; margin-top: 20px;"
    >
      <el-table-column prop="seriesName" label="系列名称" min-width="180">
        <template slot-scope="scope">
          <span class="series-name">{{ scope.row.seriesName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="spend" label="花费" width="120" align="right">
        <template slot-scope="scope">
          {{ scope.row.spend || '0.00' }}
        </template>
      </el-table-column>
      <el-table-column prop="impressions" label="展示次数" width="120" align="right">
        <template slot-scope="scope">
          {{ scope.row.impressions || '0' }}
        </template>
      </el-table-column>
      <el-table-column prop="clicks" label="点击次数" width="120" align="right">
        <template slot-scope="scope">
          {{ scope.row.clicks || '0' }}
        </template>
      </el-table-column>
      <el-table-column prop="ctr" label="点击率" width="120" align="right">
        <template slot-scope="scope">
          {{ (scope.row.ctr || 0).toFixed(2) }}%
        </template>
      </el-table-column>
      <el-table-column prop="cpc" label="平均点击成本" width="120" align="right">
        <template slot-scope="scope">
          {{ scope.row.cpc || '0.00' }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <div class="pagination-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'FacebookManagement',
        data() {
            return {
                dateRange: [],
                searchKeyword: '',
                loading: false,
                tableData: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                summaryData: {},
                pickerOptions: {
                    shortcuts: [
                        {
                            text: '最近一周',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近一个月',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                                picker.$emit('pick', [start, end])
                            }
                        },
                        {
                            text: '最近三个月',
                            onClick(picker) {
                                const end = new Date()
                                const start = new Date()
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                                picker.$emit('pick', [start, end])
                            }
                        }
                    ]
                }
            }
        },
        created() {
            this.initDateRange()
            this.fetchData()
        },
        methods: {
            initDateRange() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                this.dateRange = [start, end]
            },
            async fetchData() {
                this.loading = true
                try {
                    const [startDate, endDate] = this.dateRange || []
                    const response = await this.$http.get('/launch/data/dash/board/fb/list', {
                        params: {
                            pageNum: this.currentPage,
                            pageSize: this.pageSize,
                            startDate: startDate ? startDate.toISOString().split('T')[0] : undefined,
                            endDate: endDate ? endDate.toISOString().split('T')[0] : undefined,
                            keyword: this.searchKeyword || undefined
                        }
                    })

                    if (response.data.header.code === 0) {
                        this.tableData = response.data.body.data
                        this.total = response.data.body.total
                        this.summaryData = response.data.body.summary || {}
                    } else {
                        this.$message.error(response.data.header.msg || '获取数据失败')
                    }
                } catch (error) {
                    this.$message.error(error.message || '获取数据失败')
                } finally {
                    this.loading = false
                }
            },
            handleDateChange() {
                this.currentPage = 1
                this.fetchData()
            },
            handleSearch() {
                this.currentPage = 1
                this.fetchData()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.fetchData()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.fetchData()
            }
        }
    }
</script>

<style scoped>
    .facebook-management {
        padding: 20px;
    }

    /* 头部样式 */
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .header h2 {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }

    .header-controls {
        display: flex;
        gap: 15px;
    }

    /* 汇总部分样式 */
    .summary-section {
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
    }

    .summary-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .summary-label {
        font-size: 14px;
        color: #909399;
    }

    .summary-value {
        font-size: 20px;
        font-weight: 500;
        color: #303133;
    }

    .summary-value.time {
        font-size: 14px;
        font-weight: normal;
    }

    /* 表格样式 */
    .series-name {
        display: inline-block;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /* 分页样式 */
    .pagination-container {
        margin-top: 20px;
        text-align: right;
    }

    /* 响应式调整 */
    @media screen and (max-width: 768px) {
        .header {
            flex-direction: column;
            gap: 15px;
        }

        .header-controls {
            flex-direction: column;
            width: 100%;
        }

        .el-date-picker {
            width: 100%;
        }

        .el-input {
            width: 100%;
        }
    }
</style> 