import { render, staticRenderFns } from "./DetailedData.vue?vue&type=template&id=4758f906&scoped=true"
import script from "./DetailedData.vue?vue&type=script&lang=js"
export * from "./DetailedData.vue?vue&type=script&lang=js"
import style0 from "./DetailedData.vue?vue&type=style&index=0&id=4758f906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4758f906",
  null
  
)

export default component.exports