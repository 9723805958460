<template>
  <div class="data-groups">
    <div class="header">
      <div class="header-controls">
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
          <el-tab-pane v-if="showAdminTab" label="数据组管理" name="admin"></el-tab-pane>
          <el-tab-pane v-if="showBuyerTab" label="买量站数据组" name="buyer"></el-tab-pane>
        </el-tabs>
        <div class="control-buttons">
          <el-input
            v-model="searchKeyword"
            :placeholder="activeTab === 'admin' ? '搜索SID' : '搜索站点URL'"
            clearable
            @clear="handleSearch"
            @keyup.enter.native="handleSearch"
            style="width: 200px"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
          <el-button type="primary" @click="handleAdd">{{ activeTab === 'admin' ? '新增数据组' : '新增买量站数据组' }}</el-button>
        </div>
      </div>
    </div>

    <div class="content-wrapper">
      <!-- 正常数据组表格 -->
      <el-table
        v-if="activeTab === 'admin'"
        v-loading="storeLoading"
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column prop="sid" label="SID"></el-table-column>
        <el-table-column prop="platformType" label="平台">
          <template slot-scope="scope">
            {{ scope.row.platformType || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 买量站数据组表格 -->
      <el-table
        v-if="activeTab === 'buyer'"
        v-loading="buyingSiteLoading"
        :data="buyingSiteGroups"
        border
        style="width: 100%">
        <el-table-column prop="webSiteUrl" label="站点URL"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="small"
              @click="handleBuyingSiteDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 新增数据组对话框 -->
    <el-dialog
      :title="dialogType === 'add' ? '新增数据组' : '编辑数据组'"
      :visible.sync="dialogVisible"
      width="30%"
      @close="resetForm">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="SID" prop="sid">
          <el-input 
            v-model.trim="form.sid" 
            :disabled="dialogType === 'edit'"
            placeholder="请输入SID">
          </el-input>
        </el-form-item>
        <el-form-item label="平台" prop="platforms">
          <el-checkbox-group v-model="form.platforms">
            <el-checkbox label="tiktok" value="tiktok">TikTok</el-checkbox>
            <el-checkbox label="fb" value="fb">FB</el-checkbox>
            <el-checkbox label="kwai" value="kwai">KWai</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增买量站数据组对话框 -->
    <el-dialog
      title="新增买量站数据组"
      :visible.sync="buyingSiteDialogVisible"
      width="30%"
      @close="resetBuyingSiteForm">
      <el-form :model="buyingSiteForm" :rules="buyingSiteRules" ref="buyingSiteForm" label-width="80px">
        <el-form-item label="站点URL" prop="webSiteUrl">
          <el-input 
            v-model.trim="buyingSiteForm.webSiteUrl" 
            placeholder="请输入站点URL">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyingSiteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleBuyingSiteSubmit" :loading="buyingSiteSubmitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapState } from 'vuex'

  export default {
    name: 'DataGroups',
    data() {
      return {
        activeTab: 'admin',
        searchKeyword: '',
        dialogVisible: false,
        submitting: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        form: {
          sid: '',
          platforms: []
        },
        rules: {
          sid: [
            { required: true, message: '请输入SID', trigger: 'blur' },
            { pattern: /^\d+$/, message: 'SID必须为数字', trigger: 'blur' }
          ]
        },
        dialogType: 'add',
        editingId: null,
        tableData: [],
        buyingSiteGroups: [],
        buyingSiteLoading: false,
        buyingSiteDialogVisible: false,
        buyingSiteSubmitting: false,
        buyingSiteForm: {
          webSiteUrl: ''
        },
        buyingSiteRules: {
          webSiteUrl: [
            { required: true, message: '请输入站点URL', trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      ...mapState('user', ['userType']),
      dataGroups() {
        return this.$store.state.dataGroups.dataGroups
      },
      storeLoading() {
        return this.$store.state.dataGroups.loading
      },
      storeTotal() {
        return this.$store.state.dataGroups.total
      },
      showAdminTab() {
        return this.userType == 1 || this.userType == '1,2'
      },
      showBuyerTab() {
        return this.userType == 2 || this.userType == '1,2'
      }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      async fetchData() {
        this.loading = true
        try {
          const response = await this.$http.get('/launch/data/group/list', {
            params: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              searchKeyword: this.searchKeyword,
              type: this.activeTab === 'admin' ? 'admin' : 'buyer'
            }
          })

          if (response.data.header.code === 0) {
            this.tableData = response.data.body.data
            this.total = response.data.body.total
          } else {
            this.$message.error(response.data.header.msg || '获取数据失败')
          }
        } catch (error) {
          this.$message.error(error.message || '获取数据失败')
        } finally {
          this.loading = false
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1  // 切换每页显示数量时重置为第一页
        if (this.activeTab === 'buyer') {
          this.fetchBuyingSiteGroups()
        } else {
          this.fetchData()
        }
      },
      handleCurrentChange(val) {
        this.pageNum = val
        if (this.activeTab === 'buyer') {
          this.fetchBuyingSiteGroups()
        } else {
          this.fetchData()
        }
      },
      resetForm() {
        this.$refs.form?.resetFields()
        this.form.sid = ''
        this.form.platforms = []
        this.dialogType = 'add'
        this.editingId = null
      },
      async handleSubmit() {
        try {
          await this.$refs.form.validate()
          this.submitting = true

          // 转换平台名称为后端需要的格式
          const platformType = this.form.platforms.map(p => p.toLowerCase())

          if (this.dialogType === 'add') {
            await this.$store.dispatch('dataGroups/createDataGroup', {
              sid: this.form.sid,
              platformType
            })
          } else {
            await this.$store.dispatch('dataGroups/updateDataGroup', {
              sid: this.editingId,
              platformType
            })
          }

          this.$message.success(this.dialogType === 'add' ? '创建成功' : '更新成功')
          this.dialogVisible = false
          this.resetForm()
          await this.fetchData()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.submitting = false
        }
      },
      async handleDelete(row) {
        try {
          await this.$confirm('确认删除该数据组吗？', '提示', {
            type: 'warning'
          })

          await this.$store.dispatch('dataGroups/deleteDataGroup', row.sid)
          this.$message.success('删除成功')
          // 如果当前页只有一条数据且不是第一页，删除后自动跳转到上一页
          if (this.dataGroups.length === 1 && this.pageNum > 1) {
            this.pageNum--
          }
          await this.fetchData()
        } catch (error) {
          if (error !== 'cancel') {
            this.$message.error(error.toString())
          }
        }
      },
      async handleSearch() {
        this.pageNum = 1
        this.total = 0;
        if (this.activeTab === 'buyer') {
          this.fetchBuyingSiteGroups()
        } else {
          this.fetchData()
        }
      },
      formatPlatforms(platforms) {
        if (!platforms || !platforms.length) return '-'
        const platformMap = {
          'tt': 'tiktok',
          'fb': 'fb',
          'kwai': 'kwai'
        }
        return platforms.map(p => platformMap[p] || p).join(', ')
      },
      handleEdit(row) {
        this.dialogType = 'edit'
        this.editingId = row.sid
        this.form.sid = row.sid
        // 转换平台名称
        const platformMap = {
          'TIKTOK': 'tiktok',
          'FACEBOOK': 'fb',
          'FB': 'fb',
          'KWAI': 'kwai'
        }
        this.form.platforms = row.platformType ?
          row.platformType.split(',').map(p => platformMap[p.toUpperCase()] || p.toLowerCase()) : []
        this.dialogVisible = true
      },
      handleTabClick() {
        this.pageNum = 1
        this.total = 0;
        this.searchKeyword = ''
        if (this.activeTab === 'buyer') {
          this.fetchBuyingSiteGroups()
        } else {
          this.fetchData()
        }
      },
      async fetchBuyingSiteGroups() {
        try {
          console.log('开始加载买量站数据')
          this.buyingSiteLoading = true
          const response = await this.$http.get('/launch/buy/data/group/list', {
            params: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              webSiteUrl: this.searchKeyword || undefined
            }
          })
          console.log('买量站数据加载成功:', response.data.body.data)
          this.buyingSiteGroups = response.data.body.data
          this.total = response.data.body.total
        } catch (error) {
          console.error('买量站数据加载失败:', error)
          this.$message.error(error.toString())
        } finally {
          this.buyingSiteLoading = false
        }
      },
      resetBuyingSiteForm() {
        this.$refs.buyingSiteForm?.resetFields()
        this.buyingSiteForm.webSiteUrl = ''
      },
      async handleBuyingSiteSubmit() {
        try {
          await this.$refs.buyingSiteForm.validate()
          this.buyingSiteSubmitting = true

          await this.$http.post('/launch/buy/data/group/save', {
            webSiteUrl: this.buyingSiteForm.webSiteUrl
          })

          this.$message.success('创建成功')
          this.buyingSiteDialogVisible = false
          this.resetBuyingSiteForm()
          await this.fetchBuyingSiteGroups()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.buyingSiteSubmitting = false
        }
      },
      async handleBuyingSiteDelete(row) {
        try {
          await this.$confirm('确认删除该买量站数据组吗？', '提示', {
            type: 'warning'
          })

          await this.$http.post('/launch/buy/data/group/remove', {
          }, {
            params: {
              webSiteUrl: row.webSiteUrl
            }
          })

          this.$message.success('删除成功')
          if (this.buyingSiteGroups.length === 1 && this.pageNum > 1) {
            this.pageNum--
          }
          await this.fetchBuyingSiteGroups()
        } catch (error) {
          if (error !== 'cancel') {
            this.$message.error(error.toString())
          }
        }
      },
      handleAdd() {
        if (this.activeTab === 'admin') {
          this.dialogVisible = true
        } else {
          this.buyingSiteDialogVisible = true
        }
      },
    },
    created() {
      if (this.userType == 2) {
        this.activeTab = 'buyer'
        this.total = 0;
        this.fetchBuyingSiteGroups()
      } else {
        this.fetchData()
      }
    },
    watch: {
      activeTab: {
        immediate: true,
        handler(newVal) {
          this.pageNum = 1
          this.searchKeyword = ''
          this.total = 0;
          if (newVal === 'buyer') {
            this.fetchBuyingSiteGroups()
          } else {
            this.fetchData()
          }
        }
      }
    }
  }
</script>

<style scoped>
  .data-groups {
    background-color: #f5f7fa;
    min-height: calc(100vh - 60px);
  }

  .header {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .header-controls {
    position: relative;
  }

  .control-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .content-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }

  .pagination-container {
    margin-top: 20px;
    text-align: right;
  }

  /* Element UI 组件样式覆盖 */
  :deep(.el-tabs__header) {
    margin-bottom: 0;
  }

  :deep(.el-table) {
    margin-top: 20px;
  }

  :deep(.el-table th) {
    background-color: #f5f7fa;
    color: #606266;
  }

  :deep(.el-button--text) {
    padding: 0 8px;
  }

  /* 响应式处理 */
  @media screen and (max-width: 768px) {
    .control-buttons {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
    }

    .el-input {
      width: 100% !important;
    }
  }
</style> 